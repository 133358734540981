import React from "react";
import { withPrefix } from "gatsby";
import Layout from "../../components/Layout";
import dollar from "../../images/dollar.svg";
import shield from "../../images/shield.svg";
import recycle from "../../images/recycle.svg";
import "../../styles/global.css";
import "../../styles/generic.css";
import "../../styles/mission.css";

function MissionPage() {
  return (
    <Layout>
      <main
        className="generic bg-image"
        style={{
          backgroundColor: "#2a2e35",
          backgroundImage: `url(${withPrefix("/bgmission.jpg")}`,
        }}
      >
        <div className="generic__container">
          <article className="generic__content">
            <h1>Our Mission</h1>
            <div className="generic__line" />
            <p className="generic__highlight">
              Our mission is to introduce a new level of awareness about large
              industrial fire disasters by introducing new, cost- and
              performance-effective methods of controlling, managing and
              suppressing fires.
            </p>
            <p>
              Swiss Fire Protection Research and Development AG was established
              by determined industrial fire safety experts with a singular focus
              on establishing a higher safety status in the energy industry,
              especially when it comes to highly exposed large-diameter
              atmospheric storage tanks and dikes.
            </p>
            <p>
              At SFPRD, a dedicated team of fire protection, chemical, HSE, and
              mechanical engineers, as well as safety protocol experts, work
              relentlessly to address seemingly unsolvable fire safety
              challenges.
            </p>
          </article>
          <div>
            <div className="mission__icon__group">
              <img src={dollar} alt="cost effective" />
              <p>Cost Effectiveness</p>
            </div>
            <div className="mission__icon__group">
              <img src={shield} alt="civil security" />
              <p>Civil Security</p>
            </div>
            <div className="mission__icon__group">
              <img src={recycle} alt="enviromental responsibility" />
              <p>Enviromental Responsibility</p>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}

export default MissionPage;
